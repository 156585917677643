.navbar-brand {
  max-width: 140px;
}
.navbar {
  -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
  font-family: $headings-font-family;
  font-weight: 600;

  .navbar-collapse {
    flex-direction: column-reverse;

    .nav-link {
      color: $body-color;
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar-brand {
    max-width: 250px;
  }
  .navbar .navbar-collapse {
    flex-direction: column-reverse;
    .contact-details {
      background: $lgrey;
      border-radius: 16px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .main-nav {
      text-transform: uppercase;
    }
  }
}

@include media-breakpoint-up(xl) {
  .navbar .navbar-collapse {
    .main-nav {
      font-size: 1.2rem;
      .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }
    .contact-details {
      margin-right: 15px;
    }
  }
}