.jumbotron {
  background-size: cover;
  background-position: center center;
  min-height: 300px;
  position: relative;
  margin: 0;

  &.jumbo-lg {
    min-height: 600px;
  }

  .cta {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@include media-breakpoint-down(sm) {
  .jumbotron {
    background-image: none !important;
    min-height: auto;
    padding-bottom: 80px;

    .page-heading {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    &.jumbo-lg {
      min-height: auto;
    }
  }
}


.cta {
  width: 100%;
  padding: 1rem;
  background: $red;
  text-align: center;
  font-size: 1rem;
  color: #fff;
}
@include media-breakpoint-up(md) {
  .cta {
    font-size: 1.3rem;
  }
}

.block {
  -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
  max-width: 350px;
  margin: 0 auto 20px auto;
  background: #fff;
}

.services {
  position: relative;

  &::after {
    content: '';
    clip-path: ellipse(83% 80% at 50% 97%);
    width: 100%;
    height: 500px;
    display: block;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(243,244,244,1) 20%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(243,244,244,1) 20%);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(243,244,244,1) 20%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f3f4f4",GradientType=1);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}

@include media-breakpoint-down(sm) {
  .logos {
    img {
      max-width: 80px;
    }
  }
}

footer {
  padding: 20px 0;
  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }

  h4 {
    display: inline-block;
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
    margin: 20px 0;
    padding-bottom: 10px;

  }

  hr {
    border-top: 1px solid rgba($color: #fff, $alpha: 0.2);
  }

  .social {
    li {
      margin-right: 15px;
    }
    a{
      display: block;
      border: 1px solid #fff;
      border-radius: 50%;
      padding: 3px 9px;
      width: 40px;
      height: 40px;
      svg {
        fill: #fff;
      }
      &:hover {
        border-color: $blue;
        svg {
          fill: $blue;
        }
      }
    }
  }
}