$theme-colors: ("primary": #e4003a,
  "dark": #000000);

$red: #e4003a;
$blue: #009fe3;
$secondary: $blue;
$lgrey: #F3F4F4;
$dgrey: #474748;

$body-color: #474748;
$font-family-sans-serif: 'sofia-pro', sans-serif;
$font-weight-base: 300;

$headings-font-family: 'omnes-pro', sans-serif;
$headings-font-weight: 500;
$font-size-base: 1rem;

@import "node_modules/bootstrap/scss/bootstrap";
@import "src/sass/global";
@import "src/sass/navigation";
@import "src/sass/page";