body {
  padding-top: 55px;
}
@include media-breakpoint-up(lg) {
  body {
    padding-top: 96px;
  } 
}
strong {
  font-weight: 500;
}

.btn {
  border-radius: 18px;
  padding: 0.5rem 2rem;
  border-width: 2px;
}

.narrow-container {
  max-width: 950px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

section {
  position: relative;
}

.lead {
  margin: 2rem 0 3rem 0;
}

.default-padding {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.col-red {
  color: $red !important;
}

.col-blue {
  color: $blue !important;
}

.bg-lgrey {
  background: $lgrey;
}
.bg-dgrey {
  background: $dgrey;
  color: #fff;
}
.page-heading {
  font-size: 3rem;
}

.heading {
  font-size: 2rem;
}

.sub-heading {
  font-size: 1.5rem;
}
.bullets {
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 0;
  padding: 0;
  text-align: left;
  list-style: none;

  li {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 1.2rem;
    margin-bottom: 15px;

    &::before {
      position: absolute;
      top: 10px;
      left: 0;
      content: "";
      display: inline-block;
      /* By using an em scale, the arrows will size with the font */
      width: 0.4em;
      height: 0.4em;
      border-right: 0.15em solid $red;
      border-top: 0.15em solid $red;
      transform: rotate(45deg);
      margin-right: 0.5em;
    }
  }
}

@include media-breakpoint-up(md) {
  .page-heading {
    font-size: 4rem;
  }
  .heading {
    font-size: 3rem;
  }
  .sub-heading {
    font-size: 2rem;
  }
}

form {
  .form-control {
    border-radius: 10px;
    margin-bottom: 10px;
  }
}

@include media-breakpoint-up(lg) {
  .image-curve {
    position: absolute;
    width: 50%;
    height: 100%;

    img {
      width: 100%;
      height:100%;
      object-fit:cover;
    }

    &::after {
      -webkit-clip-path: ellipse(39% 69% at 0% 65%);
      clip-path: ellipse(39% 69% at 0% 65%);
      content: '';
      width: 50%;
      height: 100%;
      display: block;
      background: #fff;
      position: absolute;
      top: 0;
    }

    &.left {
      right: 0;
      top: 0;

      &::after {
        -webkit-clip-path: ellipse(39% 69% at 0% 65%);
        clip-path: ellipse(39% 69% at 0% 65%);
        left: -1px;
      }
    }

    &.right {
      left: 0;
      top: 0;

      &::after {
        -webkit-clip-path: ellipse(39% 69% at 0% 65%);
        clip-path: ellipse(39% 69% at 0% 65%);
        right: -1px;
        transform: scaleX(-1);
      }
    }
  }

  .bg-lgrey .image-curve::after {
    background: $lgrey;
  }
}

#lightgallery {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 2px;
	margin: 0;
	a {
		width: 50%;
        padding: 2px;
        img{
            width: 100%;
            height: auto;
        }
        &:hover {
            img {
                opacity: 0.5;
            }
        }
	}
}

@include media-breakpoint-up(lg) {
  #lightgallery  a {
    width: 25%;
  }
}
